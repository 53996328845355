<template>
<div>

  <v-footer
    
    color="#E4E5E7"
    class="pa-0 pb-12 d-none d-sm-block"
  >
    <v-container>
        <v-row class="pt-6">
            <v-col cols="12" xl="4" lg="4" md="4" sm="6">
              <v-img max-width="130"  :src="weblogo" alt="logo"></v-img>
                
                <p class="footer--text my-5">
                  <router-link to="/" class="router-link-active" style="color:#000">
                    AHUI</router-link>
                    là đối tác tiếp thị liên kết chính thức và lớn nhất của Alibaba và Tencent tại Việt Nam. Hệ thống được tài trợ công nghệ và sử dụng dữ liệu trực tiếp từ chương trình tiếp thị liên kết của Alibaba và Tencent nhắm đem tới lợi ích tốt nhất cho người dùng. Hiện tại, AHUI.VN đang là hệ thống chiết khấu taobao 1688 pinduoduo cao nhất thị trường.
                  
                </p>
                <div  class="d-flex flex-sm-nowrap flex-wrap">
                  <a href="#" class="me-3 mb-3">
                    <v-img
                      max-width="130"
                      src="@/assets/images/home/appstore.png"
                    ></v-img>
                  </a>
                  <a href="#" class="me-3 mb-3">
                    <v-img
                      max-width="130"
                      src="@/assets/images/home/Googleplay.png"
                    ></v-img>
                  </a>
                </div>
                <!-- <p class=" my-5">Powered by Taocarts 2021 独立站/Taobao优惠管理系统</p> -->
                <div id="SRbIH4y" style="text-align:center"></div>
            </v-col>
            

            <v-col cols="12" xl="2" lg="2" md="4" sm="6">
                <h4 class="mb-6">{{ $t("新手上路") }}</h4>
                

                <div class="my-2"><a href="/fanli-Strategy" class="text-decoration-none footer-link-hover ">{{ $t("常见问题") }}</a></div>
                <div class="my-1"><a href="/fanli-Strategy" class="text-decoration-none footer-link-hover ">{{ $t("帮助中心") }}</a></div>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="4" sm="6">
                <h4 class="mb-6">{{ $t("关于我们") }}</h4>
                <div class="my-2"><a href="/fanli-Strategy" class="text-decoration-none footer-link-hover ">{{ $t("联系我们") }}</a></div>
                <div class="my-1"><a href="/fanli-Strategy" class="text-decoration-none footer-link-hover ">{{ $t("隐私声明") }}</a></div>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="4" sm="6">
                <h4 class="mb-6">{{ $t("联系我们") }}</h4>
                <p class="" v-for="(item , index) in contactdata" :key='index'>
                  <span>{{item.name}}</span>:
                  <span>{{item.value}}</span>
                </p>
                
                <v-btn
                    v-for="icon in icons"
                    :key="icon"
                    class="me-2 white--text"
                    elevation="0"
                    x-small
                    fab
                    dark
                    color="dark darken-1"
                >
                <v-icon small size="24px">
                    {{ icon }}
                </v-icon>
                </v-btn>
                <p class="mt-2">
                  <a href="https://www.dmca.com/r/0p69rey" title="DMCA.com Protection Status" target="_blank" class="dmca-badge ft-corp-img-link dmca_protected" data-wpel-link="external" rel="external noopener noreferrer"> <img src="https://images.dmca.com/Badges/dmca-badge-w100-2x1-02.png?ID=d8049b84-d847-40ee-8782-ecde449fdda0" alt="DMCA.com Protection Status" /></a>  
                </p>
                <div id="SRbIH4y" style="text-align:center"></div>
            </v-col>
            <v-col cols="12" >
              <h4 style="text-align: center;" class="mb-6">©{{year}} By <router-link to="/" class="router-link-active" style="color:#000">
                    AHUI.VN</router-link> Chiết khấu taobao 1688 pinduoduo tốt nhất thị trường.</h4>
            </v-col>
        </v-row>
    </v-container>
  </v-footer>
  <v-card height='56' class="d-sm-none d-flex" flat color="rgba(0,0,0,0)"></v-card>
  </div>
</template>

<script>
  export default {
    name: 'Footer',
    data: () => ({
      icons: [
        'mdi-facebook',
        'mdi-wechat',
        'mdi-twitter',
        // 'mdi-google',
        // 'mdi-instagram',
      ],
      contactdata:[],
      weblogo:"",
      aboutUs: ['Careers', 'Our Stores', 'Our Cares', 'Terms & Conditions', 'Privacy Policy'],
      customerCare: ['Help Center', 'How to Buy', 'Track Your Order', 'Corporate & Bulk Purchasing', 'Returns & Funds'],
      year:''
    }),
    mounted(){
      this.year=new Date().getFullYear()
      this. getbanner()
    },
    methods:{
      getbanner() {
        this.contactdata=[]
      this.$api.home.banner().then((res) => {
        if (res.status) {
         this.weblogo=res.data.logo[0].value
         let caontact=res.data.contact
          for(var i in caontact){
            let obj={
              name:'',
              value:""
            }
            obj.name=i
            obj.value=caontact[i]
            this.contactdata.push(obj)
          }
        }
      });
    },
    }
  }
</script>
<style lang="scss">
a.footer-link-hover {
    color: #000 !important;
    &:hover {
        color: #BDBDBD !important;
    }
}
.footer--text {
  color: #333333;
}
.v-footer {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.router-link-active {
  text-decoration: none;
  color: #000;
}
</style>